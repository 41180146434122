import http from "@/http";
import eventBus from "@/events";
import Token from "@/token";

const Payment = {
    getPolicy: async function(id) {
        const resp = await http('/payment/' + id + '/policy');
        return resp.data;
    },
    findAll: async function (ctx) {
        if(ctx) {
            const page = ctx.currentPage - 1 || 0;
            const size = ctx.perPage || 20;
            const sortDesc = ctx.sortDesc ? ',desc' : '';
            const sort = (ctx.sortBy || 'number') + sortDesc;
            const filter = ctx.filter ? ctx.filter : {};
            const pagination = {page, size, sort, sortDesc};
            const params = {...filter, ...pagination};
            const endpoint = Token.hasPermission('PAYMENT_LIST_ALL_PERMISSION') ? "/payment/search/all" : '/users/search/userPayments';
            const resp = await http(endpoint, {params});
            eventBus.$emit('LIST_COUNT_PAYMENT_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.payments;
        } else {
            const resp = await http("/payment/search/all");
            eventBus.$emit('LIST_COUNT_PAYMENT_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.payments;
        }
    },
    requisition: function(id) {
      return http.get('/payment/' + id + '/requisition');
    },
    create: function(data) {
        return http.post('/payment', data);
    },
    update: function (id, data) {
        return http.patch('/payment/' + id, data);
    },
    findById(id) {
        return http.get('/payment/' + id);
    },
    enable(id) {
        return http.patch('/payment/' + id + '/enable')
    },
    disable(id) {
        return http.patch('/payment/' + id + '/disable')
    },
    deleteById(id) {
        return http.delete('/payment/' + id)
    },
    findPaymentInvoices: function(id) { return http.get('undefined'.replace('{id}', id)); }
};

export default Payment;

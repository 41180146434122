<template>
  <div>
    <div class="pb-3">
      <!-- create button -->
      <b-button v-has-permission="'PAYMENT_CREATE_PERMISSION'" @click="add()" variant="dark">
        <b-icon-plus-circle></b-icon-plus-circle>
        <span class="ml-1">{{ $t('LIST_ADD_LABEL', {catalog: $t('PAYMENT')})  | capitalize }}</span>
      </b-button>
      <!-- create button -->

      <!-- refresh button -->
      <b-button class="ml-2" @click="refresh" variant="dark">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('LIST_REFRESH_LABEL')  | capitalize }}</span>
      </b-button>
      <!-- refresh button -->

      <!-- clear button -->
      <b-button class="ml-1" @click="clear" variant="dark">
        <b-icon-x></b-icon-x>
        <span class="ml-1">{{ $t('LIST_FILTER_CLEAR_LABEL') | capitalize }}</span>
      </b-button>
      <!-- clear button -->

      <b-button class="ml-1 btn btn-dark" v-b-toggle.filter-collapse>
        <b-icon-search class="mr-1"></b-icon-search>
        {{$t('FILTER_LABEL') | capitalize}}
      </b-button>

      <!-- Excel export -->
      <download-excel class="ml-2 btn btn-dark"
                      :data="items"
                      :fields="excelFields"
                      :worksheet="labels.module"
                      :name="labels.excel">
        <b-icon-download class="mr-1"></b-icon-download>
        {{ $t('LIST_EXCEL_DOWNLOAD_LABEL') | capitalize }}
      </download-excel>
      <!-- Excel export -->
    </div>
    <b-collapse id="filter-collapse" class="mt-2">
      <!-- List page filter -->
      <payment-list-filter :only-active="onlyActive" class="d-inline-block w-75" @search="setFilter"
                           v-model="filter"></payment-list-filter>
      <!-- List page filter -->

      <!-- Page size -->
      <div class="d-inline-block pl-1 w-25">
        <page-size v-model="size"></page-size>
      </div>
      <!-- Page size -->
    </b-collapse>

    <!-- table -->
    <b-table
        id="payment-table"
        ref="paymentTable"
        striped
        hover
        bordered
        small
        table-variant="dark"
        selectable
        :select-mode="selectMode"
        v-model="items"
        :current-page="page"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :filter="filter"
        :items="listAll">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('PAYMENTS_LABEL')})  | capitalize }}</strong>
        </div>
      </template>

      <!-- donor -->
      <template #head(donor)="">
        <span>{{ labels.donor | capitalize }}</span>
      </template>

      <template #cell(donor)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.donor }}</span>
        </div>
      </template>
      <!-- donor -->

      <!-- number -->
      <template #head(number)="">
        <span>{{ labels.number | capitalize }}</span>
      </template>

      <template #cell(number)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.number }}</span>
        </div>
      </template>
      <!-- number -->

      <!-- polNumber -->
      <template #head(polNumber)="">
        <span>{{ labels.polNumber | capitalize }}</span>
      </template>

      <template #cell(polNumber)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.polNumber }}</span>
        </div>
      </template>
      <!-- polNumber -->
      
      <!-- segment -->
      <template #head(expenditureType)="">
        <span>{{ labels.expenditureType | capitalize }}</span>
      </template>

      <template #cell(expenditureType)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.expenditureType }}</span>
        </div>
      </template>
      <!-- segment -->
      <!-- account -->
      <template #head(donorAccount)="">
        <span>{{ labels.donorAccount | capitalize }}</span>
      </template>

      <template #cell(donorAccount)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.donorAccount }}</span>
        </div>
      </template>
      <!-- account -->
      <!-- dueDate -->
      <template #head(dueDate)="">
        <span>{{ labels.dueDate | capitalize }}</span>
      </template>

      <template #cell(dueDate)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.dueDate | date }}</span>
        </div>
      </template>
      <!-- dueDate -->
      <!-- total -->
      <template #head(total)="">
        <span>{{ labels.total | capitalize }}</span>
      </template>

      <template #cell(total)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.total | currency }}</span>
        </div>
      </template>
      <!-- total -->
      <!-- concept -->
      <template #head(concept)="">
        <span>{{ labels.concept | capitalize }}</span>
      </template>

      <template #cell(concept)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.concept }}</span>
        </div>
      </template>
      <!-- concept -->
      <!-- observations -->
      <template #head(observations)="">
        <span>{{ labels.observations | capitalize }}</span>
      </template>

      <template #cell(observations)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.observations }}</span>
        </div>
      </template>
      <!-- observations -->

      <template #head(enabled)="">
        <span>{{ labels.enabled  | capitalize }}</span>
      </template>

      <template #cell(enabled)="data">
        <div style="text-align: center">
          <b-icon-check-circle variant="success" v-if="data.item.enabled"></b-icon-check-circle>
          <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
        </div>
      </template>

      <template #head(options)="">
        <span>{{ $t('LIST_OPTIONS_LABEL')  | capitalize }}</span>
      </template>

      <template #cell(options)="data">
        <div style="text-align: center">
          <b-dropdown size="sm" id="dropdown-1" right class="m-md-2">
            <b-dropdown-item v-has-permission="'PAYMENT_UPDATE_PERMISSION'" @click="edit(data.item.id)">
              {{ $t('LIST_EDIT_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item @click="download(data.item)">
              {{ $t('PAYMENT_PDF_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'PAYMENT_DELETE_PERMISSION'"
                             @click="remove(data.item.id, data.item.label)">{{ $t('LIST_DELETE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'PAYMENT_DISABLE_PERMISSION'"
                             @click="disable(data.item.id, data.item.label)" v-if="data.item.enabled">
              {{ $t('LIST_DISABLE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'PAYMENT_ENABLE_PERMISSION'"
                             @click="enable(data.item.id, data.item.label)" v-else>
              {{ $t('LIST_ENABLE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item @click="showPolicy(data.item)">
              {{ $t('LIST_SHOW_POLICY_LABEL')  | capitalize }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <!-- table -->

    <b-modal size="xl" id="policy-modal" :title="$t('POLICY_PREVIEW_TITLE') | capitalize">
      <div v-if="loadingPolicy" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('POLICY_LABEL')})  | capitalize }}</strong>
      </div>
      <div v-else>
        <div v-if="policy">
          <div>
            <b>{{$t('POLICY_CONCEPT_LABEL') | capitalize}}: </b> <span>{{policy.concept}}</span>
          </div>
          <div>
            <b>{{$t('POLICY_DATE_LABEL') | capitalize}}: </b> <span>{{policy.date | date}}</span>
          </div>
          <h6>
            <b>{{$t('POLICY_DETAILS_LABEL')  | capitalize}}</b>
          </h6>
          <b-table-simple v-if="policy.details && policy.details.length > 0">
            <b-tr>
              <b-th>{{$t('POLICY_ACCOUNT_LABEL') | capitalize}}</b-th>
              <b-th>{{$t('POLICY_AMOUNT_LABEL') | capitalize}}</b-th>
              <b-th>{{$t('POLICY_TYPE_LABEL') | capitalize}}</b-th>
              <b-th>{{$t('POLICY_SEGMENT_LABEL') | capitalize}}</b-th>
            </b-tr>
            <b-tr v-for="detail in policy.details" :key="detail.account">
              <b-td>{{detail.account}}</b-td>
              <b-td>{{detail.amount | currency}}</b-td>
              <b-td>{{detail.type}}</b-td>
              <b-td>{{detail.segment}}</b-td>
            </b-tr>
          </b-table-simple>

          <b-alert v-else variant="warning" show>{{$t('POLICY_HAS_NO_DETAILS') | capitalize}}</b-alert>

          <h6>
            <b>{{$t('POLICY_INVOICES_LABEL') | capitalize}}</b>
          </h6>
          <ul v-if="policy.invoices && policy.invoices.length > 0">
            <li v-for="invoice in policy.invoices" :key="invoice">{{invoice}}</li>
          </ul>
          <b-alert v-else variant="danger" show>{{$t('POLICY_HAS_NO_INVOICES') | capitalize}}</b-alert>
          <h6>
            <b>{{$t('POLICY_MESSAGES_LABEL') | capitalize}}</b>
          </h6>
          <ul v-if="policy.messages && policy.messages.length > 0">
            <li v-for="message in policy.messages" :key="message">{{message}}</li>
          </ul>
          <b-alert v-else show>{{$t('POLICY_HAS_NO_MESSAGES') | capitalize}}</b-alert>
        </div>
      </div>
    </b-modal>

    <b-container fluid>
      <b-row>
        <b-col>
          <b-pagination
              v-model="page"
              :total-rows="totalRows"
              :per-page="size"
              pills
              aria-controls="payment-table">
          </b-pagination>
        </b-col>
        <b-col class="text-right">
          {{ (pageTotals.size ? $t('GRID_ROW_TOTAL', pageTotals) : $t('GRID_ALL_ROWS_TOTAL')) | capitalize }}
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {DateFilter, Form, NumberFilter, ProcessWithLoadingAndMessage, Table} from "@/mixins";
import payments from "@/payment";
import eventBus from "@/events";
import PaymentListFilter from "@/payment/PaymentListFilter";
import PageSize from "@/components/PageSize";
import Reports from "@/reports";
import Payment from "@/payment";

export default {
  name: "PaymentList",
  props: ['selectMode', 'currentlySelected', 'onlyActive'],
  components: {PageSize, PaymentListFilter},
  mixins: [Table, Form, ProcessWithLoadingAndMessage, NumberFilter, DateFilter],
  mounted() {
    eventBus.$on('LIST_COUNT_PAYMENT_EVENT', (totalElements) => {
      this.totalRows = totalElements;
    });
  },
  methods: {
    showPolicyModal() {
      this.$bvModal.show('policy-modal');
    },
    async showPolicy(payment) {
      try {
        this.loadingPolicy = true;
        this.showPolicyModal();
        this.policy = await Payment.getPolicy(payment.id);
      } catch (e) {
        this.sendError('ERROR_LOADING_POLICY', payment, e);
      } finally {
        this.loadingPolicy = false;
      }
    },
    async download(payment) {
      try {
        this.isBusy = true;
        const params = {id: payment.id, fromDate: new Date(), toDate: new Date(), fromNumber: 0, toNumber: 0};
        await Reports.download('PAYMENT_PDF_FORMAT', params, `${payment.number}.pdf`);
      } catch (e) {
        this.sendError('ERROR_PDF_MESSAGE', {}, e);
      } finally {
        this.isBusy = false;
      }
    }
  },
  data() {
    return {
      labels: {
        number: this.$t('PAYMENT_NUMBER_LABEL'),
        polNumber: this.$t('PAYMENT_POL_NUMBER_LABEL'),
        donor: this.$t('PAYMENT_DONOR_LABEL'),
        donorAccount: this.$t('PAYMENT_DONOR_ACCOUNT_LABEL'),
        supplier: this.$t('PAYMENT_SUPPLIER_LABEL'),
        supplierAccount: this.$t('PAYMENT_SUPPLIER_ACCOUNT_LABEL'),
        expenditureType: this.$t('PAYMENT_EXPENDITURE_TYPE_LABEL'),
        account: this.$t('PAYMENT_ACCOUNT_LABEL'),
        dueDate: this.$t('PAYMENT_DUE_DATE_LABEL'),
        total: this.$t('PAYMENT_TOTAL_LABEL'),
        concept: this.$t('PAYMENT_CONCEPT_LABEL'),
        observations: this.$t('PAYMENT_OBSERVATIONS_LABEL'),
        enabled: this.$t('LIST_ENABLED_LABEL'),
        module: this.$t('PAYMENTS_LABEL'),
        excel: this.$t('PAYMENTS_LABEL') + '.xls',
      },
      registerPage: 'PAYMENT_REGISTER_TITLE',
      editPage: 'PAYMENT_EDIT_TITLE',
      selectedCount: 0,
      allSelected: false,
      originals: [],
      loadingPolicy: false,
      policy: undefined
    }
  },
  computed: {
    controller() {
      return payments;
    },
    fields() {
      return [
        {key: 'number', sortable: true, tdClass: 'customTd'},
        {key: 'polNumber', sortable: true, tdClass: 'customTd'},
        {key: 'donor', sortable: true, tdClass: 'customTd'},
        {key: 'donorAccount', sortable: true, tdClass: 'customTd'},
        {key: 'supplier', sortable: true, tdClass: 'customTd'},
        {key: 'supplierAccount', sortable: true, tdClass: 'customTd'},
        {key: 'expenditureType', sortable: true, tdClass: 'customTd'},
        {key: 'dueDate', sortable: true, tdClass: 'customTd'},
        {key: 'total', sortable: true, tdClass: 'customTd'},
        {key: 'enabled', sortable: true, tdClass: 'enabledTd'},
        {key: 'options', sortable: false, tdClass: 'optionsTd'}];
    },
    tableRef() {
      return this.$refs.paymentTable;
    },
    excelFields() {
      const fields = {};
      fields[this.labels.number] = 'number';
      fields[this.labels.polNumber] = 'polNumber';
      fields[this.labels.donor] = 'donor';
      fields[this.labels.donorAccount] = 'donorAccount';
      fields[this.labels.supplier] = 'supplier';
      fields[this.labels.supplierAccount] = 'supplierAccount';
      fields[this.labels.expenditureType] = 'expenditureType';
      fields[this.labels.dueDate] = 'dueDate';
      fields[this.labels.total] = 'total';
      fields[this.labels.concept] = 'concept';
      fields[this.labels.observations] = 'observations';
      return fields;
    }
  }
}
</script>

<style scoped>
.selectTd {
  width: 15px !important;
}

.nameTd {
  width: 75% !important;
}
</style>

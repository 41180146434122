<template>
  <div>
    <b-form class="mb-2" inline>
      <div class="w-100 mb-1">
        <b-form-input @blur="search"
                      v-model="filter.text"
                      class="w-100"
                      :placeholder="$t('FILTER_SEARCH_BY_TEXT_LABEL', {catalog: $t('PAYMENT')}) | capitalize"
                      id="search-input"></b-form-input>
      </div>
      <div class="w-100 mb-1">
        <b-form-input @blur="search"
                      v-model="filter.number"
                      class="w-100"
                      :placeholder="$t('FILTER_SEARCH_BY_NUMBER_LABEL', {catalog: $t('PAYMENT')}) | capitalize"
                      id="search-input"></b-form-input>
      </div>

      <div class="w-100 mb-1">
        <div class="d-inline-block w-50">
          <b-form-datepicker
              @input="search"
              v-model="filter.from"
              right
              locale="es-MX"
              button-variant="dark"
              aria-controls="example-input"
              :label-no-date-selected="$t('FILTER_FROM_LABEL') | capitalize"></b-form-datepicker>
        </div>

        <div class="d-inline-block w-50 pl-1">
          <b-form-datepicker
              @input="search"
              v-model="filter.to"
              right
              locale="es-MX"
              button-variant="dark"
              aria-controls="example-input"
              :label-no-date-selected="$t('FILTER_TO_LABEL') | capitalize"></b-form-datepicker>
        </div>
      </div>

      <b-form-select v-if="!onlyActive" @change="search" v-model="filter.status" class="w-25 mb-1" id="status-select">
        <b-form-select-option :value="undefined">{{
            $t('FILTER_ALL_LABEL', {
              catalog: $t('PAYMENT'),
              gender: 'o'
            })  | capitalize
          }}
        </b-form-select-option>
        <b-form-select-option :value="true">{{
            $t('FILTER_ACTIVE_LABEL', {
              catalog: $t('PAYMENT'),
              gender: 'o'
            })  | capitalize
          }}
        </b-form-select-option>
        <b-form-select-option :value="false">{{
            $t('FILTER_INACTIVE_LABEL', {
              catalog: $t('PAYMENT'),
              gender: 'o'
            })  | capitalize
          }}
        </b-form-select-option>
      </b-form-select>

      <div class="w-100 mb-1">
        <project-single-selector @input="search" value-type="id" undefined-means="PROJECT_FILTER_ALL_LABEL" v-model="filter.projectId"></project-single-selector>
      </div>
    </b-form>
  </div>
</template>

<script>
import {VModel, CapitalFilter, Filter} from "@/mixins";
import ProjectSingleSelector from "@/project/ProjectSingleSelector";

export default {
  name: "PaymentListFilter",
  components: {ProjectSingleSelector},
  props: ['onlyActive'],
  mixins: [VModel, CapitalFilter, Filter],
  data() {
    return {
      filter: {}
    }
  },
  methods: {
    search() {
      if (this.onlyActive) {
        this.filter.status = true;
      }
      this.$emit('search', this.filter);
    }
  }
}
</script>

<style scoped>

</style>